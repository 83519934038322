<template>
  <div class="home">
    <!-- ======== header start ======== -->
    <header class="header">
      <div class="navbar-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 text-center">
              <nav class="navbar navbar-expand-lg">
                <h2 class="mx-auto" style="font-family: 'Oleo Script', Sans-serif; color: #9F4F42;">Wedding Invitation</h2>
                <!-- navbar collapse -->
              </nav>
              <!-- navbar -->
            </div>
          </div>
          <!-- row -->
        </div>
        <!-- container -->
      </div>
      <!-- navbar area -->
    </header>
    <!-- ======== header end ======== -->

    <!-- ======== hero-section start ======== -->
    <section id="home" class="hero-section">
      <div class="container animate-box">
        <div class="row align-items-center position-relative">
          <div class="col-lg-12 text-center">
            <div class="hero-img wow fadeInUp" data-wow-delay=".5s">
              <img src="assets/img/hero/Logo-Art.png" width="20%" alt="" />
              <h1 v-if="client.hajat == 'wanita'" class="wow fadeInUp" data-wow-delay=".4s" style="font-family: 'Yellowtail', cursive; margin-bottom: 15px;">
               {{client.nama_wanita}} & {{client.nama_pria}}
              </h1>
              <h1 v-if="client.hajat == 'pria'" class="wow fadeInUp" data-wow-delay=".4s" style="font-family: 'Yellowtail', cursive; margin-bottom: 15px;">
                {{client.nama_pria}} & {{client.nama_wanita}}
              </h1>
              <p v-if="config.countdown === 1" class="wow fadeInUp" data-wow-delay=".6s" style="font-family: 'Titan One', cursive; margin-bottom: 15px; color: #9F4F42;">
                {{ config.language === 1 ? moment(client.tgl_resepsi).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_resepsi).locale("id").format("dddd, DD MMMM YYYY") }}
              </p>
              <p v-else class="wow fadeInUp" data-wow-delay=".6s" style="font-family: 'Titan One', cursive; margin-bottom: 15px; color: #9F4F42;">
                {{ config.language === 1 ? moment(client.tgl_akad).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======== hero-section end ======== -->

    <!-- ======== feature-section start ======== -->
    <section id="features" class="feature-section pt-120 pb-120" >
      <img class="corner-top-left" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-top-right" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-left" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-right" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <div class="container">
        <div v-if="client.hajat == 'wanita'" class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center animate-box" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px;">
            {{ config.language === 1 ? "The Bride's" : 'Mempelai' }}
          </h2>
          <div class="col-lg-4 col-md-8 col-sm-10 animate-box">
            <div class="single-feature">
              <div class="icon">
                <img class="mx-auto text-center" :src="'https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_wanita" alt=""/>
              </div>
              <div class="content">
                <h3 style="font-family: 'Yellowtail', cursive;">{{client.gelar_wanita}}</h3>
                <h4><strong>{{ config.language === 1 ? "Daughter of" : 'Putri Dari' }}</strong></h4>
                <p style="color: #C06B67;">
                  {{client.nama_ayah_wanita}}
                </p>
                <p style="color: #C06B67;">&amp;</p>
                <p style="color: #C06B67;">{{client.nama_ibu_wanita}}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-8 col-sm-10 animate-box">
            <div class="single-feature">
              <div class="icon-love">
                <p class="heart">
                  <i class="lni lni-heart-filled"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-8 col-sm-10 animate-box">
            <div class="single-feature">
              <div class="icon">
                <img class="mx-auto text-center" :src="'https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_pria" alt=""/>
              </div>
              <div class="content">
                <h3 style="font-family: 'Yellowtail', cursive;">{{client.gelar_pria}}</h3>
                <h4><strong>{{ config.language === 1 ? "Son of" : 'Putra Dari' }}</strong></h4>
                <p style="color: #C06B67;">{{client.nama_ayah_pria}}</p>
                <p style="color: #C06B67;">&amp;</p>
                <p style="color: #C06B67;">{{client.nama_ibu_pria}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="client.hajat == 'pria'" class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center animate-box" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px;">
            {{ config.language === 1 ? "The Bride's" : 'Mempelai' }}
          </h2>
          <div class="col-lg-4 col-md-8 col-sm-10 animate-box">
            <div class="single-feature">
              <div class="icon">
                <img class="mx-auto text-center" :src="'https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_pria" alt=""/>
              </div>
              <div class="content">
                <h3 style="font-family: 'Yellowtail', cursive;">{{client.gelar_pria}}</h3>
                <h4><strong>{{ config.language === 1 ? "Son of" : 'Putra Dari' }}</strong></h4>
                <p>{{client.nama_ayah_pria}}</p>
                <p>&amp;</p>
                <p>{{client.nama_ibu_pria}}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-8 col-sm-10 animate-box">
            <div class="single-feature">
              <div class="icon-love">
                <p class="heart">
                  <i class="lni lni-heart-filled"></i>
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-8 col-sm-10 animate-box">
            <div class="single-feature">
              <div class="icon">
                <img class="mx-auto text-center" :src="'https://admin.itsyourdayofficial.com/foto_calon/'+client.foto_wanita" alt=""/>
              </div>
              <div class="content">
                <h3 style="font-family: 'Yellowtail', cursive;">{{client.gelar_wanita}}</h3>
                <h4><strong>{{ config.language === 1 ? "Daughter of" : 'Putri Dari' }}</strong></h4>
                <p >
                  {{client.nama_ayah_wanita}}
                </p>
                <p>&amp;</p>
                <p>{{client.nama_ibu_wanita}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======== feature-section end ======== -->

    <!-- ======== about-section start ======== -->
    <section id="about" class="about-section pt-100 pb-50">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" data-aos="fade-up" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">
            {{ config.language === 1 ? 'Date and Venue of the Event' : 'Waktu dan Tempat Acara' }}
          </h2>
          <div class="col-md-12 col-md-offset-0">
            <div class="col-12" style="border-radius: 10px; border-style: solid; background:#f6bdc0; border-width: 5px; border-color: #CC5B61; width: 100%;">
              <div class="row" style="">
                <div data-aos="fade-up" class="col-md-12 text-center simply-countdown">
                  <div class="simply-section simply-days-section">
                    <span class="simply-amount">{{days}}</span>
                    <span class="simply-word">
                      {{ config.language === 1 ? 'Days' : 'Hari' }}
                    </span>
                  </div>
                  <div class="simply-section simply-hours-section">
                    <span class="simply-amount">{{hours}}</span>
                    <span class="simply-word">
                      {{ config.language === 1 ? 'Hours' : 'Jam' }}
                    </span>
                  </div>
                  <div class="simply-section simply-minutes-section">
                    <span class="simply-amount">{{minutes}}</span>
                    <span class="simply-word">
                      {{ config.language === 1 ? 'Minutes' : 'minutes' }}
                    </span>
                  </div>
                  <div class="simply-section simply-minutes-section">
                    <span class="simply-amount">{{seconds}}</span>
                    <span class="simply-word">
                      {{ config.language === 1 ? 'Seconds' : 'Detik' }}
                    </span>
                  </div>
                </div>
                <div class="col-md-5 col-sm-4 offset-md-1 text-center" style="margin-bottom:10px; margin-top: 10px;">
                  <div class="event-wrap animate-box">
                    <h3 style="margin-bottom:6px; font-family: 'Gulzar', serif; color:#CC5B61;">Akad</h3>
                    <h4 style="margin-bottom:2px; font-family: 'Gulzar', serif; color:#CC5B61;">{{ config.language === 1 ? 'Location' : 'Lokasi Acara' }}</h4>
                    <h2 style="color: rgb(159, 79, 66); border-bottom: 3px solid #CC5B61; display: block; padding-bottom: 10px;letter-spacing: 2px; font-family: 'Oleo Script', Sans-serif;">{{client.nama_venue}}</h2>
                    <div style="color:rgb(159, 79, 66)" class="event-col mt-2">
                      <i class="lni lni-calendar"></i>&nbsp;
                      <span>{{ config.language === 1 ? moment(client.tgl_akad).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_akad).locale("id").format("dddd, DD MMMM YYYY") }}</span>
                    </div>
                    <div style="color:rgb(159, 79, 66)" class="event-col mb-3">
                      <i class="lni lni-alarm-clock"></i>&nbsp;
                      <span v-if="client.tgl_akad_selesai == null" style="font-weight: bold;">{{ config.language === 1 ? moment(client.tgl_akad).format("HH:mm") + ' - End' : moment(client.tgl_akad).format("HH:mm") + ' - Selesai' }}</span>
                      <span v-else style="font-weight: bold;">{{ moment(client.tgl_akad).format("HH:mm") }} - {{ moment(client.tgl_akad_selesai).format("HH:mm") }}</span>
                    </div>
                    <p style="color:rgb(159, 79, 66)" v-html="client.alamat_venue"></p>
                    <div v-if="client.url_maps_resepsi !== null" class="col-md-12 mt-3">
                      <a :href="client.url_maps" target="_blank" class="main-btn border-btn btn-hover wow fadeInUp btn btn-sm"><i class="lni lni-map-marker" style="font-size:20px;"></i> Buka Map</a>
                    </div>
                    <div v-else-if="config.maps_section !== 1" class="col-md-12 mt-3">
                      <a :href="client.url_maps" target="_blank" class="main-btn border-btn btn-hover wow fadeInUp btn btn-sm"><i class="lni lni-map-marker" style="font-size:20px;"></i> Buka Map</a>
                    </div>
                    <div v-else-if="client.url_maps_resepsi == null && config.maps_section === 1" class="col-md-12 mt-3">
                      <!-- <a :href="client.url_maps" target="_blank" class="main-btn border-btn btn-hover wow fadeInUp btn btn-sm"><i class="lni lni-map-marker" style="font-size:20px;"></i> Buka Map</a> -->
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-sm-5 text-center" style="margin-bottom:10px; margin-top: 10px;">
                  <div class="event-wrap animate-box">
                    <h3 style="margin-bottom:6px; font-family: 'Gulzar', serif; color:#CC5B61;">{{ config.language === 1 ? 'Reception' : 'Resepsi' }}</h3>
                    <h4 style="margin-bottom:2px; font-family: 'Gulzar', serif; color:#CC5B61">{{ config.language === 1 ? 'Location' : 'Lokasi' }}</h4>
                    <h2 style="color: rgb(159, 79, 66); border-bottom: 3px solid #CC5B61; display: block; padding-bottom: 10px;letter-spacing: 2px; font-family: 'Oleo Script', Sans-serif;">{{client.nama_venue_resepsi}}</h2>
                    <div style="color:rgb(159, 79, 66)" class="event-col mt-2">
                      <i class="lni lni-calendar"></i>&nbsp;
                      <span>{{ config.language === 1 ? moment(client.tgl_resepsi).locale("en").format("dddd, DD MMMM YYYY") : moment(client.tgl_resepsi).locale("id").format("dddd, DD MMMM YYYY") }}</span>
                    </div>
                    <div style="color:rgb(159, 79, 66)" class="event-col mb-3">
                      <i class="lni lni-alarm-clock"></i>&nbsp;
                      <span v-if="client.tgl_resepsi_selesai == null" style="font-weight: bold;">{{ config.language === 1 ? moment(client.tgl_resepsi).format("HH:mm") + ' - End' : moment(client.tgl_resepsi).format("HH:mm") + ' - Selesai' }}</span>
                      <span v-else style="font-weight: bold;">{{ moment(client.tgl_resepsi).format("HH:mm") }} - {{ moment(client.tgl_resepsi_selesai).format("HH:mm") }}</span>
                    </div>
                    <p style="color:rgb(159, 79, 66)" v-html="client.alamat_venue_resepsi"></p>
                    <div v-if="client.url_maps_resepsi !== null" class="col-md-12 mt-3">
                      <a :href="client.url_maps_resepsi" target="_blank" class="main-btn border-btn btn-hover wow fadeInUp btn btn-sm"><i class="lni lni-map-marker" style="font-size:20px;"></i>{{ config.language === 1 ? 'Open Map' : 'Buka Map' }}</a>
                    </div>
                    <div v-else-if="config.maps_section !== 1" class="col-md-12 mt-3">
                      <a :href="client.url_maps" target="_blank" class="main-btn border-btn btn-hover wow fadeInUp btn btn-sm"><i class="lni lni-map-marker" style="font-size:20px;"></i>{{ config.language === 1 ? 'Open Map' : 'Buka Map' }}</a>
                    </div>
                    <div v-else-if="client.url_maps_resepsi == null && config.maps_section === 1" class="col-md-12 mt-3">
                      <!-- <a :href="client.url_maps" target="_blank" class="main-btn border-btn btn-hover wow fadeInUp btn btn-sm"><i class="lni lni-map-marker" style="font-size:20px;"></i> Buka Map</a> -->
                    </div>
                  </div>
                </div>
              </div>
              
              
            </div>
          </div>  
        </div>
      </div>
    </section>
    <!-- ======== about-section end ======== -->

    <!-- ======== feature-section start ======== -->
    <section v-if="config.maps_section === 1"  id="about" class="about-section pt-100 pb-50" style="background: rgb(246, 189, 192);">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? 'Maps' : 'Peta Lokasi' }}</h2>
          <!-- <div class="col-md-12 col-md-offset-0">
            <div class="col-12" style="border-radius: 10px; border-style: solid; border-width: 5px; border-color: #5864ff; background-color: #eee; width: 100%; height: 250px" id="map"></div>
          </div> -->
          <div class="container text-center">
            <!-- <div class="col-12" v-html="client.embed" style="border-radius: 10px; border-style: solid; border-width: 5px; border-color: #5864ff; background-color: #eee; width: 100%; height: 250px"></div> -->
            <iframe class="ftco-animate" :src="client.embed" width="95%" height="450" style="border-radius: 20px !important; border-style: solid; border-width: 2px; border-color: #CC5B61; margin: auto; margin-top: 10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div class="col-md-12 col-md-offset-0 text-center pt-20">
            <div class="display-t">
              <div class="display-tc animate-box" data-animate-effect="fadeIn">
                <a :href="client.url_maps" target="_blank" class="main-btn border-btn btn-hover wow fadeInUp"><i class="lni lni-map-marker" style="font-size:20px;"></i>{{ config.language === 1 ? 'Open Map' : 'Buka Map' }}</a>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </section>
    <!-- ======== feature-section end ======== -->
    

    <section v-if="config.story_section === 1" id="about" class="about-section pt-100 pb-50" >
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? 'Our Story' : 'Kisah Kami' }}</h2>
          <div class="row">
            <div class="col-md-12 col-md-offset-0">
              <ul class="timeline animate-box">
                <li v-if="story.type1 !== undefined" class="media animate-box">
                  <div class="timeline-badge" style="background-image:url(images/couple-1.jpg);"></div>
                  <div class="timeline-media">
                    <img v-if="story.type1.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media" width="100%" alt="">
                  <video v-if="story.type1.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h3 class="timeline-title">{{story.title1.title}}</h3>
                      <span class="date">{{story.month1.rangeTime}}</span>
                    </div>
                    <div class="timeline-body pt-2">
                      <p v-html="story.description1.description"></p>
                    </div>
                  </div>
                </li>
                <li v-if="story.type2 !== undefined" class="timeline-inverted animate-box">
                  <div class="timeline-badge" style="background-image:url(images/couple-2.jpg);"></div>
                  <div class="timeline-media">
                    <img v-if="story.type2.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media" width="100%" alt="">
                    <video v-if="story.type2.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                     <h3 class="timeline-title">{{story.title2.title}}</h3>
                     <span class="date">{{story.month2.rangeTime}}</span>
                    </div>
                    <div class="timeline-body pt-2">
                      <p v-html="story.description2.description"></p>
                    </div>
                  </div>
                </li>
                <li v-if="story.type3 !== undefined" class="media animate-box">
                  <div class="timeline-badge" style="background-image:url(images/couple-3.jpg);"></div>
                  <div class="timeline-media">
                    <img v-if="story.type3.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media" width="100%" alt="">
                    <video v-if="story.type3.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h3 class="timeline-title">{{story.title3.title}}</h3>
                      <span class="date">{{story.month3.rangeTime}}</span>
                    </div>
                    <div class="timeline-body pt-2">
                      <p v-html="story.description3.description"></p>
                    </div>
                  </div>
                </li>
                <li v-if="story.type4 !== undefined" class="timeline-inverted animate-box">
                  <div class="timeline-badge" style="background-image:url(images/couple-2.jpg);"></div>
                  <div class="timeline-media">
                    <img v-if="story.type4.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media" width="100%" alt="">
                    <video v-if="story.type4.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h3 class="timeline-title">{{story.title4.title}}</h3>
                      <span class="date">{{story.month4.rangeTime}}</span>
                    </div>
                    <div class="timeline-body pt-2">
                      <p v-html="story.description4.description"></p>
                    </div>
                  </div>
                </li>
                <li v-if="story.type5 !== undefined" class="media animate-box">
                  <div class="timeline-badge" style="background-image:url(images/couple-3.jpg);"></div>
                  <div class="timeline-media">
                    <img v-if="story.type5.type == 'foto'" :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media" width="100%" alt="">
                    <video v-if="story.type5.type == 'video'" width="100%" height="280px;" :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media +'#t=0.001'" type="video/mp4" controls preload="metadata"></video>
                  </div>
                  <div class="timeline-panel">
                    <div class="timeline-heading">
                      <h3 class="timeline-title">{{story.title5.title}}</h3>
                      <span class="date">{{story.month5.rangeTime}}</span>
                    </div>
                    <div class="timeline-body pt-2">
                      <p v-html="story.description5.description"></p>
                    </div>
                  </div>
                </li>  
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>  

    <!-- ======== subscribe-section start ======== -->
    <section id="features" class="feature-section pt-120" >
      <img class="corner-top-left" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-top-right" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-left" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-right" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px;">{{ config.language === 1 ? 'Photo Gallery' : 'Galeri Foto' }}</h2>
          <div class="col-md-12 pt-50 pb-50 show-gallery">
            <!-- <split-carousel :item-width="235" :height="350" >
                <split-carousel-item  v-for="item in gallery" :key="item.id">
                    <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file" class="img-thumbnail" alt="">
                </split-carousel-item>
            </split-carousel> -->
            <!-- <div class="row justify-content-center">
                <div v-for="item in gallery" :key="item.id" class="col-lg-4 col-md-8 col-sm-10">
                    <div class="text-center" style="margin-bottom: 20px;">
                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file" style="border-radius: 5px; width:90%;" class="img-fluid img-thumbnail" alt="...">
                    </div>
                </div>
            </div> -->
            <div class="col-md-12">
                <vue-horizontal responsive :button="false" ref="horizontal" @scroll-debounce="onScroll">
                    <div class="item" v-for="(item, index) in gallery" :key="index">
                        <img v-img:group :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file"  class="gallery img d-flex align-items-center justify-content-center" alt="...">
                    </div>
                </vue-horizontal>
                <header class="pagination">
                  <!-- <h2>My header</h2> -->
                  <nav>
                    <button @click="prev" :class="{'active': hasPrev, 'inactive': !hasPrev}">
                      <i class="lni lni-chevron-left"></i>
                    </button>
                    <button @click="next" :class="{'active': hasNext, 'inactive': !hasNext}">
                      <i class="lni lni-chevron-right"></i>
                    </button>
                  </nav>
                </header>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======== subscribe-section end ======== -->

    <section v-if="config.rsvp_by_wa_section === 1" id="about" class="about-section pt-90 pb-50" style="background: rgb(246, 189, 192);">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? 'Reservation' : 'Reservasi' }}</h2>
          <div class="col-md-10">
            <p style="color:rgb(159, 79, 66)" class="text-center confirmation">
              {{ config.language === 1 ? 'It is an honor and happiness for we as a family if you wish to attend to give blessings to the bride and groom Thank you for your presence and blessings.' : 'Merupakan suatu kehormatan dan kebahagiaan bagi kami sekeluarga apabila Bapak/Ibu/Saudara/i berkenan hadir untuk memberikan doa restu kepada kedua mempelai atas kehadiran serta doa restu, ucapkan terimakasih.' }}
            </p>
          </div>
          <div class="row col-md-12 mt-10" style="border-radius: 10px; border-style: solid; background:rgb(246, 189, 192); border-width: 5px; border-color: rgb(246, 189, 192); width: 100%;">
            <form class="form-inline mt-20">
              <div class="col-md-8 offset-md-2">
                <div class="form-group">
                  <input type="name" v-model="name" class="form-control" id="name" :placeholder="config.language === 1 ? 'Please Fill Your Name' : 'Masukkan Nama Anda'" autocomplete="off" required>
                </div>
                <div class="col-md-8 mt-2">
                  <div class="form-group">
                    <input type="radio" id="one" value="Iya, Saya akan Hadir" v-model="picked" required/>
                    <label for="one" style="color:rgb(159, 79, 66)">&nbsp;{{ config.language === 1 ? 'Yes, I will attend' : 'Iya, Saya akan Hadir' }}</label>
						      </div>
                </div>
                <div class="col-md-8 ">
                  <div class="form-group">
							      <input type="radio" id="two" value="Maaf, Saya Tidak Bisa Hadir" v-model="picked" required/>
                    <label for="two" style="color:rgb(159, 79, 66)">&nbsp;{{ config.language === 1 ? 'Sorry, I cant attend' : 'Maaf, Saya Tidak Bisa Hadir' }}</label>
						      </div>
                </div>
                <div class="col-md-12 text-center pt-20 pb-20">
                  <a :href="'https://api.whatsapp.com/send?phone=' + client.wa_number + '&text= Hai, saya ' + name + ' ingin konfirmasi kehadiran undangan pernikahan bahwa ' + picked + '. Terima kasih'" target="_blank" type="submit" class="main-btn border-btn btn-hover wow fadeInUp">RSVP Via Whatsapp</a>
                </div>
              </div>
            </form>
          </div>
          
        </div>
      </div>
    </section>

    <section id="about" class="about-section pt-90 pb-50">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? 'Prayer Quotes' : "Kutipan Do'a" }}</h2>
          <div class="col-md-12 col-md-offset-0">
            <div class="col-12" style="border-radius: 10px; border-style: solid; background:rgb(246, 189, 192); border-width: 5px; border-color: rgb(246, 189, 192); width: 100%;">
              <div class="row" style="margin-bottom:10px;">
                <div style="z-index: 1000;" class="col-md-12 mx-auto text-center">
                  <img style="width:auto; margin-bottom:-180px;" src="/assets/img/template/top-flower.png" alt="">
                </div>
                <div class="col-md-5 mx-auto text-center mt-70" style="margin-bottom:10px;">
                  <div class="event-wrap animate-box">
                    <p class="quotes mb-20 pt-70" v-html="client.quote" style="color:rgb(159, 79, 66)"></p>
                    <h4 class="pb-50" style="margin-bottom:2px; font-family: 'Gulzar', serif; ">{{client.title_quote}}</h4>
                  </div>
                </div>
                <div class="col-md-12 mx-auto text-center">
                  <img style="width:auto; margin-top:-120px;" src="/assets/img/template/bot-flower.png" alt="">
                </div>
              </div>
              
              
            </div>
          </div>  
        </div>
      </div>
    </section>

    <section id="about" class="about-section pt-90 pb-50" style="background: rgb(246, 189, 192);">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? "Wedding Wish" : 'Ucapan Pernikahan' }}</h2>
          <div class="col-md-10">
            <p class="text-center confirmation" style="color:rgb(159, 79, 66)">
              {{ config.language === 1 ? "Send Prayers & Greetings to the Bride and Groom" : 'Kirimkan Doa & Ucapan Kepada kedua Mempelai' }}
            </p>
          </div>
          <div class="row col-md-12 mt-10" style="border-radius: 10px; border-style: solid; background:rgb(246, 189, 192); border-width: 5px; border-color: rgb(246, 189, 192); width: 100%;">
            <form class="form-inline mt-20">
              <div class="col-md-8 offset-md-2">
                <div class="form-group mb-2">
                  <input type="name" v-model="submit.name" class="form-control" id="name" :placeholder="config.language === 1 ? 'Please Fill Your Name' : 'Masukkan Nama Anda'" autocomplete="off" required>
                </div>
                <span v-if="errors.name" style="margin-bottom:10px; background:#e79552" class="badge badge-danger">
                  {{ config.language === 1 ? "Please fill in your name" : 'Harap isi nama anda' }}
                </span>
                <div class="form-group mb-2">
                  <!-- <input type="name" v-model="submit.confirmation" class="form-control" id="name" placeholder="Masukkan Nama Anda" autocomplete="off" required> -->
                  <select v-model="submit.confirmation" class="form-control" >
                    <option value="" disabled selected>
                      {{ config.language === 1 ? "Confirmation of attendance" : 'Konfirmasi Kehadiran' }}
                    </option>
                    <option value="hadir">{{ config.language === 1 ? "Attend" : 'Hadir' }}</option>
                    <option value="mungkin">{{ config.language === 1 ? "Maybe Attend" : 'Mungkin Hadir' }}</option>
                    <option value="tidak">{{ config.language === 1 ? "Not Attend" : 'Tidak Hadir' }}</option>
                  </select>
                </div>
                <span v-if="errors.confirmation" style="margin-bottom:10px; background:#e79552" class="badge badge-danger">
                  {{ config.language === 1 ? "Please fill your attendance confirmation" : 'Harap isi konfirmasi kehadiran anda' }}
                </span>
                <div class="form-group mb-2">
                  <textarea type="name" v-model="submit.comment" class="form-control" id="pesan" :placeholder="config.language === 1 ? 'Write Your Message' : 'Tulis Pesan Anda'"  autocomplete="off" required cols="30" rows="10"></textarea>
						    </div>
                <!-- <span v-if="errors.comment" style="margin-bottom:10px; background:#e79552" class="badge badge-danger">
                  Harap isi pesan anda
                </span> -->
                <div class="col-md-12 text-center pt-20 pb-20">
                  <button @click.prevent="post"  type="submit" class="main-btn border-btn btn-hover wow fadeInUp">{{ config.language === 1 ? "Send Hopes" : 'Kirim Ucapan' }}</button>
                </div>
              </div>
            </form>
          </div>
          <div class="row col-md-12 widget" style="margin-top:10px; width: 100%;">
            <!-- <div class="col-md-12"> -->
              <ul class="list-group">
                <li v-for="h in hopes" :key="h.id" class="list-group-item">
                  <div class="row">
                    <div class="col-3 col-sm-1">
                      <img src="/assets/img/template/wedding-rings-2.png" class="img-responsive" style="border:5px solid rgb(246, 189, 192); border-radius:50%; width:60px;" alt="" />
                    </div>
                    <div class="col-9 col-sm-11" style="background:rgb(246, 189, 192); border-radius:10px;">
                      <div>
                        <b style="font-size:18px;">{{h.name}}</b>
                      </div>
                      <div>
                        <p v-if="h.confirmation == 'hadir'" style="font-size:14px;font-style: oblique;">{{ config.language === 1 ? "Attend" : 'Hadir' }}</p>
                        <p v-if="h.confirmation == 'mungkin'" style="font-size:14px;font-style: oblique;">{{ config.language === 1 ? "Maybe Attend" : 'Mungkin Hadir' }}</p>
                        <p v-if="h.confirmation == 'tidak'" style="font-size:14px;font-style: oblique;">{{ config.language === 1 ? "Not Attend" : 'Tidak Hadir' }}</p>
                      </div>
                      <div class="comment-text">
                        <label for="one" style="font-size:16px;">"{{h.comment}}"</label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              
            <!-- </div> -->
          </div>
          
        </div>
      </div>
    </section>

    <section v-if="config.gift_section === 1" id="about" class="about-section pt-90 pb-50" >
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? "Wedding Gift" : 'Hadiah Pernikahan' }}</h2>
          <div class="col-md-10">
            <p class="text-center confirmation" style="color:rgb(159, 79, 66)">
              {{ config.language === 1 ? "For those of you who wish to send wedding gifts, you can do so via:" : 'Bagi Bapak/Ibu/Saudara/i yang ingin mengirimkan hadiah pernikahan dapat melalui:' }}
            </p>
          </div>
          <div class="row col-md-6 mt-10 text-center" style="border-radius: 10px; border-style: solid; background:rgb(246, 189, 192); border-width: 5px; border-color: rgb(246, 189, 192);">
            <!-- <form class="form-inline mt-20"> -->
            <div class="col-12" style="border:2px solid rgb(159, 79, 66); margin-top: 10px;">
              <div class="col-12 mx-auto mt-3 mb-3"> 
                <p class="card-text" v-if="client.bank == 'BCA'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bca.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank}}</p>
                <p class="card-text" v-if="client.bank == 'BRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px"> <br> A.n {{client.acc_bank}}</p>
                <p class="card-text" v-if="client.bank == 'OVO'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/ovo.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank}}</p>
                <p class="card-text" v-if="client.bank == 'MANDIRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/mandiri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank}}</p>
                <p class="card-text" v-if="client.bank == 'GOPAY'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank}}</p>
              </div>
              <div class="input-group col-12 mx-auto mb-3">
                <input type="text" v-model="client.no_acc" class="form-control" id="myInput">
                <div class="input-group-append">
                  <button data-target="#collapseExample" class="btn btn-outline-secondary" style="color:rgb(159, 79, 66)" type="button" @click.prevent="myFunction" id="button-addon2">Copy</button>
                </div>
              </div>
            </div>

            <div v-if="client.bank2 != null" class="col-12" style="border:2px solid rgb(159, 79, 66); margin-top: 10px;">
              <div class="col-12 mx-auto mt-3 mb-3"> 
                <p class="card-text" v-if="client.bank2 == 'BCA'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bca.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank2}}</p>
                <p class="card-text" v-if="client.bank2 == 'BRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px"> <br> A.n {{client.acc_bank2}}</p>
                <p class="card-text" v-if="client.bank2 == 'OVO'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/ovo.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank2}}</p>
                <p class="card-text" v-if="client.bank2 == 'MANDIRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/mandiri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank2}}</p>
                <p class="card-text" v-if="client.bank2 == 'GOPAY'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank2}}</p>
              </div>
              <div class="input-group col-12 mx-auto mb-3">
                <input type="text" v-model="client.no_acc2" class="form-control" id="myInput2">
                <div class="input-group-append">
                  <button data-target="#collapseExample2" class="btn btn-outline-secondary" style="color:rgb(159, 79, 66)" type="button" @click.prevent="myFunction2" id="button-addon2">Copy</button>
                </div>
              </div>
            </div>
            
            <div v-if="client.bank3 != null" class="col-12" style="border:2px solid rgb(159, 79, 66); margin-top: 10px;">
              <div class="col-12 mx-auto mt-3 mb-3"> 
                <p class="card-text" v-if="client.bank3 == 'BCA'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bca.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank3}}</p>
                <p class="card-text" v-if="client.bank3 == 'BRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px"> <br> A.n {{client.acc_bank3}}</p>
                <p class="card-text" v-if="client.bank3 == 'OVO'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/ovo.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank3}}</p>
                <p class="card-text" v-if="client.bank3 == 'MANDIRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/mandiri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank3}}</p>
                <p class="card-text" v-if="client.bank3 == 'GOPAY'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank3}}</p>
              </div>
              <div class="input-group col-12 mx-auto mb-3" >
                <input type="text" v-model="client.no_acc3" class="form-control" id="myInput3">
                <div class="input-group-append">
                  <button data-target="#collapseExample3" class="btn btn-outline-secondary" style="color:rgb(159, 79, 66)" type="button" @click.prevent="myFunction3" id="button-addon2">Copy</button>
                </div>
              </div>
            </div>

            <div v-if="client.bank4 != null" class="col-12" style="border:2px solid rgb(159, 79, 66); margin-top: 10px; margin-bottom: 10px;">
              <div class="col-12 mx-auto mt-3 mb-3"> 
                <p class="card-text" v-if="client.bank4 == 'BCA'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bca.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank4}}</p>
                <p class="card-text" v-if="client.bank4 == 'BRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/bri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px"> <br> A.n {{client.acc_bank4}}</p>
                <p class="card-text" v-if="client.bank4 == 'OVO'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/ovo.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank4}}</p>
                <p class="card-text" v-if="client.bank4 == 'MANDIRI'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/mandiri.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank4}}</p>
                <p class="card-text" v-if="client.bank4 == 'GOPAY'" style="padding-top: 10px; color:rgb(159, 79, 66)"><img src="assets/img/gopay-1-removebg-preview.png" alt="" style="width: 35%;padding: 0; border-radius: 10px;"> <br> A.n {{client.acc_bank4}}</p>
              </div>
              <div class="input-group col-12 mx-auto mb-3">
                <input type="text" v-model="client.no_acc4" class="form-control" id="myInput4">
                <div class="input-group-append">
                  <button data-target="#collapseExample4" class="btn btn-outline-secondary" style="color:rgb(159, 79, 66)" type="button" @click.prevent="myFunction4" id="button-addon2">Copy</button>
                </div>
              </div>
            </div>

            <div class="col-12 mx-auto mb-3  mt-3">
              <a :href="'https://api.whatsapp.com/send?phone=' + client.wa_number + '&text= Hai, saya mau mengkonfirmasi sebelumnya saya sudah mengirimkan angpao atas nama .... Terima kasih'" target="_blank" type="submit" class="main-btn border-btn btn-hover wow fadeInUp">{{ config.language === 1 ? "Confirmation" : 'Konfirmasi' }}</a>
            </div>
            <!-- </form> -->
                

          </div>
          
        </div>
      </div>
    </section>
    <section v-if="config.invitation_section === 1" id="about" class="about-section pt-90 pb-50" style="background: rgb(246, 189, 192);">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" style="padding-top: 40px;" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? "Also Invite" : 'Turut Mengundang' }}</h2>

          <div class="col-md-12 col-md-offset-0">
            <!-- <div class="col-12" style="border-radius: 10px; border-style: solid; background:rgb(246, 189, 192); border-width: 5px; border-color: rgb(246, 189, 192); width: 100%;"> -->
              <div class="row" style="margin-bottom:10px;">
                <div class="col-md-5 col-sm-4 offset-md-1 text-center" style="margin-bottom:10px;">
                  <div class="event-wrap animate-box">
                    <h2 style="color: rgb(159, 79, 66); border-bottom: 3px solid rgb(246, 189, 192); display: block; padding-bottom: 10px;letter-spacing: 2px; font-family: 'Acme', sans-serif; font-size:22px; margin-bottom:10px;">{{ config.language === 1 ? "Bride's Family" : 'Keluarga Mempelai Wanita' }}</h2>
                    <!-- <p v-for="invite in alsoInvite" :key="invite.id">
                      <i v-if="invite.type === 'pria'" class="lni lni-checkmark-circle"></i> Keluarga Besar, Purwantoro - Wonogiri
                    
                    </p> -->
                    <ul class="list-group">
                      <li v-for="invite in alsoInvite" :key="invite.id">
                        <p v-if="invite.type === 'wanita'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>                         
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-5 col-sm-5 text-center" style="margin-bottom:10px;">
                  <div class="event-wrap animate-box">
                    <h2 style="color: rgb(159, 79, 66); border-bottom: 3px solid rgb(246, 189, 192); display: block; padding-bottom: 10px;letter-spacing: 2px; font-family: 'Acme', sans-serif; font-size:22px; margin-bottom:10px;">{{ config.language === 1 ? "Groom's Family" : 'Keluarga Mempelai Pria' }}</h2>
                    <ul class="list-group">
                      <li v-for="invite in alsoInvite" :key="invite.id">
                        <p v-if="invite.type === 'pria'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>                         
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              
            <!-- </div> -->
          </div> 
          
        </div>
      </div>
    </section>

    <!-- ======== about-section start ======== -->
    <section id="about" class="about-section pt-100 pb-50">
      <div class="container">
        <div class="row justify-content-center">
          <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px; margin-bottom: 20px;">{{ config.language === 1 ? "Health Protocol" : 'Protokol Kesehatan' }}</h2>
          <div class="col-md-10 mb-3">
            <p class="text-center confirmation" style="color:rgb(159, 79, 66)">
              {{ config.language === 1 ? "Without reducing respect, due to the current situation amidst the COVID-19 pandemic, we apologize because the event will be held in accordance with government regulations and recommendations." : 'Tanpa mengurangi rasa hormat, dikarenakan situasi yang sedang terjadi ditengah pandemi COVID-19 ini kami memohon maaf karena acara akan diselenggarakan sesuai peraturan dan imbauan pemerintah.' }}
            </p>
          </div>
          <div class="col-md-6 col-md-offset-0">
            <div class="col-12 text-center" style="border-radius: 10px; border-style: solid; background:rgb(246, 189, 192); border-width: 5px; border-color: rgb(246, 189, 192); width: 100%;">
              <img style="width: 100%; max-width: 400px; height: auto;" src="/assets/img/template/COVID-19.png" alt="">
            </div>
          </div>  
        </div>
      </div>
    </section>
    <!-- ======== about-section end ======== -->

    

    <!-- ======== feature-section start ======== -->
    <section id="features" class="feature-section pt-70" >
      <img class="corner-top-left" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-top-right" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-left" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <img class="corner-bottom-right" src="assets/img/hero/left-top-border.png" alt="" style="padding: 0.5rem;">
      <div class="container">
        <div class="row justify-content-center">
          <!-- <img class="mx-auto text-center flower" src="assets/img/about/flower.png" alt=""/>
          <h2 class="mx-auto text-center animate-box" style="font-family: 'Oleo Script', Sans-serif; margin-top: -20px;">Mempelai </h2> -->
          <div class="col-md-6 mx-auto text-center">
            <div style="z-index: 1000;" class="col-md-12 mx-auto text-center">
              <img style="width:auto; margin-bottom:-150px;" src="/assets/img/template/top-flower.png" alt="">
            </div>
            <div class="event-wrap">
              <p class="pt-70 mb-2" style="color:rgb(159, 79, 66)">{{ config.language === 1 ? "It is an honor and happiness for us as a family if you are willing to be present to give your blessing to the bride and groom. We thank you for your presence and blessings." : 'Merupakan suatu kehormatan dan kebahagiaan bagi kami sekeluarga apabila Bapak/Ibu/Saudara/i berkenan hadir untuk memberikan doa restu kepada kedua mempelai. Atas kehadiran serta doa restu, kami ucapkan terima kasih.' }}</p>
              <h2 class="mb-2" style="font-family: 'Oleo Script', Sans-serif; color:rgb(159, 79, 66);">{{ config.language === 1 ? "Happy from the our Big Family" : 'Turut berbahagia Segenap keluarga besar' }}</h2>
              <h1 v-if="client.hajat == 'wanita'" class="pb-50 mb-3" style="font-family: 'Yellowtail', cursive;">{{client.nama_wanita}} & {{client.nama_pria}}</h1>
              <h1 v-if="client.hajat == 'pria'" class="pb-50 mb-3" style="font-family: 'Yellowtail', cursive;">{{client.nama_pria}} & {{client.nama_wanita}}</h1>
            </div>
            
            <div class="col-md-12 mx-auto text-center">
              <img style="width:auto; margin-top:-120px; padding-bottom:50px" src="/assets/img/template/bot-flower.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ======== feature-section end ======== -->

    <audio :src="'https://admin.itsyourdayofficial.com/song/'+ audio" ref="audioElm" loop="loop"></audio>

    <div style="z-index: 1000;" class="icon-bar">
      <button class="play" @click="play" v-if="playing"><i class="lni lni-pause"></i></button>
      <button class="play" @click="play" v-else><i class="lni lni-play"></i></button>
    </div>

    <!-- ======== footer start ======== -->
    <footer class="footer">
      <div class="container">
        <div class="widget-wrapper">
          <div class="row">
            <div class="col-md-12 text-center">
              <p style="margin-top:10px;">
                <small style="font-family: 'Oleo Script', Sans-serif; color: rgb(159, 79, 66);">Developed by <a href="" style="color: rgb(159, 79, 66);">It's Your Day Teams</a></small>
              </p>
              <p style="">
                <small style="font-family: 'Oleo Script', Sans-serif; color: rgb(159, 79, 66);">Illustration Designed by <a href="http://www.freepik.com" style="color: rgb(159, 79, 66);">Freepik</a></small>
              </p>
              <p>
              <ul class="fh5co-social-icons" style="margin-bottom:10px; margin-top:5px;">
                <li><a href="https://www.instagram.com/itsyourday_digitalinvitation/"><i class="fa fa-instagram" style="font-size:28px; color: rgb(159, 79, 66);"></i></a></li>
              </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ======== footer end ======== -->
    <a href="#" class="scroll-top btn-hover">
      <i class="lni lni-chevron-up"></i>
    </a>

    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      :client='client'
      :guest='guest'
      :config="config"
    />
  </div>
  
</template>

<script>
import Modal from '../components/Modal.vue';
import moment from 'moment';
import { SplitCarousel, SplitCarouselItem } from "vue-split-carousel";
import VueHorizontal from "vue-horizontal";
export default {
   components: {
     Modal,
     SplitCarousel,
     SplitCarouselItem,
     VueHorizontal
  },
  data(){
    return{
      client: {},
      guest: {},
      moment: moment,
      now: 0,
      count: 0,
      date: '',
      gallery: {},
      alsoInvite: {},
      config: {},
      story: {
        title1: '',
        description1: '',
        date1: '',
        month1: '',
        year1: '',
        media1: '',
        type1: '',
        order1: '',
        title2: '',
        description2: '',
        date2: '',
        month2: '',
        year2: '',
        media2: '',
        type2: '',
        order2: '',
        title3: '',
        description3: '',
        date3: '',
        month3: '',
        year3: '',
        media3: '',
        type3: '',
        order3: '',
        title4: '',
        description4: '',
        date4: '',
        month4: '',
        year4: '',
        media4: '',
        type4: '',
        order4: '',
        title5: '',
        description5: '',
        date5: '',
        month5: '',
        year5: '',
        media5: '',
        type5: '',
        order5: '',
      },
      form: {
          address: '',
          latitude : '',
          longitude : '',
          test: ''
      },
      submit : {
				client_id : '',
        name: '',
        comment: '',
        confirmation: '',
			},
      isModalVisible: true,
      playing: true,
      audio : '',
      errors : {},
      hopes: {},
      name: '',
      picked: '',
      hasPrev: false,
      hasNext: true,
      // tambahan gallery scroll sendiri
      showGallery: false
    }
  },
  mounted(){
    // this.timer_loop();

    let toParam = this.$route.query.to;
    if (toParam) {
      toParam = decodeURIComponent(toParam);
    if (toParam.includes('-')) {
      // use original parameter value
    } else {
      // use empty string as parameter value
      toParam = '';
    }
    } else {
      toParam = '';
    }
    this.$api.get('/' + this.$route.params.slug, {
      params: {
        to: toParam,
        template: window.location.origin + "/"
      }
    })
    .then(response => { 
      if (!response.data.data) {
        this.$router.push({ name: 'PageNotFound' });
        return;
      }
      var currentUrl = window.location.href;
      
      this.client = response.data.data;
      document.title = "Wedding Invitation " + response.data.data.nama_wanita + " & " + response.data.data.nama_pria + " by It'sYourDay";
      
      if(currentUrl.includes(this.client.template) == false){
        this.$router.push({ name: 'PageNotFound' });
      }

      this.guest = response.data.data;
      this.submit.client_id = this.client.id;
      this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
      this.gallery = response.data.data.gallery;
      // tambahan gallery scroll sendiri
      this.pages = this.gallery.length;
      this.alsoInvite = response.data.data.also_invite;
      this.audio = response.data.data.song;
      this.story.title1 = response.data.data.story[0];
      this.story.description1 = response.data.data.story[0];
      this.story.date1 = response.data.data.story[0];
      this.story.month1 = response.data.data.story[0];
      this.story.year1 = response.data.data.story[0];
      this.story.media1 = response.data.data.story[0];
      this.story.type1 = response.data.data.story[0];
      this.story.order1 = response.data.data.story[0];
      this.story.title2 = response.data.data.story[1];
      this.story.description2 = response.data.data.story[1];
      this.story.date2 = response.data.data.story[1];
      this.story.month2 = response.data.data.story[1];
      this.story.year2 = response.data.data.story[1];
      this.story.media2 = response.data.data.story[1];
      this.story.type2 = response.data.data.story[1];
      this.story.order2 = response.data.data.story[1];
      this.story.title3 = response.data.data.story[2];
      this.story.description3 = response.data.data.story[2];
      this.story.date3 = response.data.data.story[2];
      this.story.month3 = response.data.data.story[2];
      this.story.year3 = response.data.data.story[2];
      this.story.media3 = response.data.data.story[2];
      this.story.type3 = response.data.data.story[2];
      this.story.order3 = response.data.data.story[2];
      this.story.title4 = response.data.data.story[3];
      this.story.description4 = response.data.data.story[3];
      this.story.date4 = response.data.data.story[3];
      this.story.month4 = response.data.data.story[3];
      this.story.year4 = response.data.data.story[3];
      this.story.media4 = response.data.data.story[3];
      this.story.type4 = response.data.data.story[3];
      this.story.order4 = response.data.data.story[3];
      this.story.title5 = response.data.data.story[4];
      this.story.description5 = response.data.data.story[4];
      this.story.date5 = response.data.data.story[4];
      this.story.month5 = response.data.data.story[4];
      this.story.year5 = response.data.data.story[4];
      this.story.media5 = response.data.data.story[4];
      this.story.type5 = response.data.data.story[4];
      this.story.order5 = response.data.data.story[4];
      this.config = response.data.data.config[0];
      this.hasNext = this.gallery.length > 1;
      this.timer_loop();
      this.modifiedDate = Math.trunc(Date.parse(this.date) / 1000);
      this.$api.get('comment/' + this.client.id)
      .then(response => { 
        this.hopes = response.data.data;
      })
      // if(navigator.geolocation){
      //   navigator.geolocation.getCurrentPosition(
      //       position => {
      //           this.getAddress(
      //               response.data.data.latitude, 
      //               response.data.data.longitude
      //           );
      //           this.showUserLocationOnTheMap(
      //               response.data.data.latitude, 
      //               response.data.data.longitude
      //           );
      //       },
      //       error => {
      //           this.error = error.message;
      //       }
      //   )
      // }else{
      //   this.error = error.message;
      // }
    })
    .catch(error => {
      // this.$router.push({ name: 'PageNotFound' });
      console.log(error.response.data);
    });

    this.$refs.horizontal.scrollToIndex(1);
  },
  created(){
    if(this.isModalVisible == true){
      document.body.style.overflow = "hidden"; 
      document.body.style.position= "fixed";
    }

    // tambahan gallery scroll sendiri
    window.addEventListener('scroll', this.checkScroll);
  },
  methods:{
    // tambahan gallery scroll sendiri
    checkScroll() {
        const galleryElement = document.querySelector('.show-gallery');
        const rect = galleryElement.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        this.showGallery = isVisible;
    },
    timer_loop() {
        this.count++;
        this.now = Math.trunc(new Date().getTime() / 1000);
        if (this.count < 200 && this.modifiedDate > this.now) {
        setTimeout(this.timer_loop, 1000);
        } else {
        this.count = 0;
        }
    },

    prev() {
      this.$refs.horizontal.prev()
    },
    next() {
      this.$refs.horizontal.next()
    },
    onScroll({hasPrev, hasNext}) {
      this.hasPrev = hasPrev
      this.hasNext = hasNext
    },

    play: function(event) {
      var a = this.$refs.audioElm;
      if (a.paused) {
        a.play();
        this.playing = true;
      } else {
        a.pause();
        this.playing = false;
      }
    },

    myFunction() {
    /* Get the text field */
      var copyText = document.getElementById("myInput");
      const button = document.body.querySelector('[data-target="#collapseExample"]')
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices *
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value)
      if(button.innerText.toLowerCase() === 'copy') {
          button.innerText = 'Copied';
      }
    },
    myFunction2() {
    /* Get the text field */
      var copyText = document.getElementById("myInput2");
      const button = document.body.querySelector('[data-target="#collapseExample2"]')
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices *
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value)
      if(button.innerText.toLowerCase() === 'copy') {
          button.innerText = 'Copied';
      }
    },
    myFunction3() {
    /* Get the text field */
      var copyText = document.getElementById("myInput3");
      const button = document.body.querySelector('[data-target="#collapseExample3"]')
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices *
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value)
      if(button.innerText.toLowerCase() === 'copy') {
          button.innerText = 'Copied';
      }
    },
    myFunction4() {
    /* Get the text field */
      var copyText = document.getElementById("myInput4");
      const button = document.body.querySelector('[data-target="#collapseExample4"]')
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices *
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value)
      if(button.innerText.toLowerCase() === 'copy') {
          button.innerText = 'Copied';
      }
    },

    closeModal() {
      this.isModalVisible = false;
      var a = this.$refs.audioElm;
      a.play();
      this.playing = true;

      if(this.isModalVisible == false){
        document.body.style.position= null;
        document.body.style.overflow = null; 
      }
    },
    // getAddress(lat, long){
    //   axios.get("https://api.tomtom.com/search/2/reverseGeocode/" 
    //   + lat 
    //   + ","
    //   + long
    //   + 
    //   ".json?key=a5J9h9LsWkBXLvh0dsaJkZRfx4fVPSFB")
    //   .then(response =>{
    //       this.form.address = response.data.addresses[0].address.freeformAddress;
    //   })
    //   .catch(error => {
    //       this.error = error.message;
    //   })
    // },
    // showUserLocationOnTheMap(latitude, longitude){
    //   // create map object
    //   let map = new google.maps.Map(document.getElementById("map"), {
    //       zoom: 15,
    //       center: new google.maps.LatLng(latitude, longitude),
    //       mapTypeId:google.maps.MapTypeId.ROADMAP
    //   });
    //   // add marker
    //   new google.maps.Marker({
    //       position: new google.maps.LatLng(latitude, longitude),
    //       map:map
    //   });
    // },
    post(){
      this.$api.post('comment', this.submit)
      .then(response =>{
          this.success = response.data.success
          this.submit.name = '';
          this.submit.comment = '';
          this.submit.confirmation = '';
          this.$api.get('comment/' + this.client.id)
          .then(response => { 
            this.hopes = response.data.data;
          })
          console.log(response)
      })
      .catch(error => {
          if (error.response.status === 401) {
            this.errors = error.response.data.errors;
          }
      });
    }
  },
  computed:{
    seconds() {
        const result = (this.modifiedDate - this.now) % 60;
        return result < 0 ? 0 : result;
    },
    minutes() {
        const result = Math.trunc((this.modifiedDate - this.now) / 60) % 60;
        return result < 0 ? 0 : result;
    },
    hours() {
        const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60) % 24;
        return result < 0 ? 0 : result;
    },
    days() {
        const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60 / 24);
        return result < 0 ? 0 : result;
    },
    modifiedDate : function(){
      return Math.trunc(Date.parse(this.date) / 1000)
    }
  },
  // tambahan gallery scroll sendiri
  watch : {
    gallery() {
        this.pages = this.gallery.length;
    },

    showGallery(newVal) {
        if (newVal) {
        setInterval(() => {
            this.next();
        }, 5000);
        }
    }
  },
}
</script>

<style scoped>
.item {
  width: 240px;
  /* padding: 32px 32px 24px 32px;
  background: #f1f1f1; */
  margin-right: 20px;
  display: flex;
  align-items: center; /* added */
}

@media screen and (max-width: 768px) {
  .item {
    width: 280px;
  }
}
</style>

<!-- Pagination CSS -->
<style scoped>
.pagination {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}
</style>

<style scoped>
  /* Content styling is done with tailwind postcss @apply for brevity. */

  header .pagination{
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  nav > button {
    padding: 1.25rem;
    border-radius: 1.125rem;
    border: none;
    outline: none;
    margin: 0.25rem;
  }

  nav > button.active {
    color: #4a5568;
    border-color: #4a5568;
  }

  nav > button.inactive {
    color: #cbd5e0;
    border-color: #e2e8f0;
  }

  nav > button:focus {
    outline: none;
  }

  nav > button > svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: currentColor;
  }
</style>

<!-- Parent CSS (Container) -->
<style scoped>
main, article {
  padding: 24px 0;
}
</style>